<template>
  <div class="p-6">
    <div class="grid grid-cols-1 gap-4 justify-items-center">
      <div class="lg:w-1/2 w-full">
        <span class="required">Username</span>
        <input type="text" v-model="username" class="p-2 border w-full" />
      </div>
      <div class="lg:w-1/2 w-full">
        <span class="required">Password</span>
        <input type="password" v-model="password" class="p-2 border w-full" />
      </div>
      <div class="flex gap-2">
        <button class="p-2 bg-green-700 hover:bg-green-400 text-white w-32">
          Login
        </button>
        <button
          @click="username = password = ''"
          class="
            border
            shadow-md
            bg-gray-700
            hover:bg-gray-400
            text-white
            rounded-md
            p-2
            w-32
          "
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
};
</script>

<style>
</style>